class TimetableModel {
  constructor(timeBlocks) {
    this.timeBlocks = timeBlocks;
    this.sort();
  }

  static minuteOfWeek() {
    var datetime = new Date();
    
    var mins = datetime.getMinutes()
             + datetime.getHours()   * 60
             + datetime.getDay()     * 60 * 24;
    
    return mins;
  }

  static minutes2humanReadable(minuteOfWeek, format="weeklyDate") {
    const minuteOfHour = minuteOfWeek % 60,
          hourOfWeek   = (minuteOfWeek - minuteOfHour) / 60,
          hourOfDay    = hourOfWeek % 24,
          dayOfWeek    = (hourOfWeek - hourOfDay) / 24;

    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          dayName = weekdays[dayOfWeek];

    switch (format) {
      case "weeklyDate":
        return <>{ dayName } { hourOfDay.toString().padStart(2, "0") }:{ minuteOfHour.toString().padStart(2, "0") }</>;
        break;
      case "duration":
        return <>{ (dayOfWeek > 0) ? dayOfWeek + "d" : "" } { hourOfDay.toString().padStart(2, "0") }:{ minuteOfHour.toString().padStart(2, "0") }</>;
        break;
      default:
        return <>unknownFormat</>
    }
  }

  static mow2time(minuteOfWeek) {
    const minuteOfHour     = minuteOfWeek % 60,
          minuteOfHour2    = minuteOfHour.toString().padStart(2, "0"),
          hourOfWeek       = (minuteOfWeek - minuteOfHour) / 60,
          hourOfDay        = hourOfWeek % 24,
          hourOfDay2       = hourOfDay.toString().padStart(2, "0"),
          dayOfWeek        = (hourOfWeek - hourOfDay) / 24,
          daytime          = hourOfDay2 + ":" + minuteOfHour2,
          weektime         = dayOfWeek + "+" + daytime,
          durationReadable = ((dayOfWeek > 0) ? dayOfWeek + "d" : "") + daytime;

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          dayName = weekdays[dayOfWeek];

    const weekdays3 = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          dayName3 = weekdays3[dayOfWeek];

    const weekdays2 = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          dayName2 = weekdays2[dayOfWeek];

    const weekdays1 = ["S", "M", "T", "W", "T", "F", "S"],
          dayName1 = weekdays1[dayOfWeek];

    const weektimeReadable  = dayName + " " + daytime,
          weektimeReadable3 = dayName3 + " " + daytime;

    return {
      minuteOfWeek: minuteOfWeek,
      minuteOfHour: minuteOfHour,
      minuteOfHour2: minuteOfHour2,
      hourOfWeek: hourOfWeek,
      hourOfDay: hourOfDay,
      dayOfWeek: dayOfWeek,
      dayName: dayName,
      dayName3: dayName3,
      dayName2: dayName2,
      dayName1: dayName1,
      daytime: daytime,
      weektime: weektime,
      weektimeReadable: weektimeReadable,
      weektimeReadable3: weektimeReadable3,
      durationReadable: durationReadable
    };
  }

  static time2mow(time) {
    return -1;
  }

  createTimeblock = (activity, start, duration) => {
    if (activity != null && activity != "") {
      this.timeBlocks.push({
        activity: activity,
        start: start, 
        duration: duration 
      })
    }
    this.sort();
    return this;
  }

  deleteTimeblock = (idx) => {
    this.timeBlocks.splice(idx, 1);
    return this;
  }

  getCurrentTimeblock = (minuteOfWeek) => {
    return this.timeBlocks.find((timeBlock) => {
      return (timeBlock.start <= minuteOfWeek) && ((timeBlock.start + timeBlock.duration) > minuteOfWeek) 
    });
  }

  getTimeblocks = (start, end) => {
    return this.timeBlocks.filter((timeBlock) => {
      return (timeBlock.start <= start) && ((timeBlock.start + timeBlock.duration) >= start) 
    });
  }

  validate() {
    return true;
  }

  sort() {
    this.timeBlocks.sort(function(a,b){return a.start - b.start});
  }
}

export default TimetableModel;