import { start } from '@popperjs/core';
import React from 'react';
import TimetableModel from './TimetableModel';



class TimeblockEditor extends React.Component {
  constructor(props) {
    super(props);
    let currentMow = TimetableModel.minuteOfWeek(),
        initDuration = 60;

    this.state = {
      activity: "",
      startDow: TimetableModel.mow2time(currentMow).dayOfWeek,
      startTime: TimetableModel.mow2time(currentMow).daytime,
      startMow: currentMow,
      durationTime: "01:00",
      duration: initDuration,
      endTime: TimetableModel.mow2time(currentMow + initDuration).daytime,
      endDayRel: 0,
      start: 0,
    };
  }

  recalcTimes = (calcIn) => {
    // A note to my future self: This method is a hot candidate for refactoring and optimization.
    let calcOut = {};

    if (calcIn.startDow) {
      calcOut.startDow = parseInt(calcIn.startDow);
      calcOut.startMow = calcOut.startDow*24*60 + this.timeToMinutes(this.state.startTime);

      let endMow = calcOut.startMow + this.state.duration;
      calcOut.endTime = TimetableModel.mow2time(endMow).daytime;
      calcOut.endDayRel = TimetableModel.mow2time(endMow).dayOfWeek - calcOut.startDow;
    }

    if (calcIn.startTime) {
      calcOut.startTime = calcIn.startTime;
      calcOut.startMow = this.state.startDow*24*60 + this.timeToMinutes(calcIn.startTime);

      let endMow = calcOut.startMow + this.state.duration;
      calcOut.endTime = TimetableModel.mow2time(endMow).daytime;
      calcOut.endDayRel = TimetableModel.mow2time(endMow).dayOfWeek - this.state.startDow;
    }

    if(calcIn.durationTime) {
      calcOut.durationTime = calcIn.durationTime;
      calcOut.duration = this.timeToMinutes(calcIn.durationTime);

      let endMow = this.state.startMow + calcOut.duration;
      calcOut.endTime = TimetableModel.mow2time(endMow).daytime;
      calcOut.endDayRel = TimetableModel.mow2time(endMow).dayOfWeek - this.state.startDow;
    }

    if(calcIn.endTime) {
      calcOut.endTime = calcIn.endTime;
      let endMow = this.state.startDow*24*60 + this.timeToMinutes(calcIn.endTime);
      calcOut.endDayRel = 0;
      if (endMow < this.state.startMow) {
        endMow += 24*60;
        calcOut.endDayRel = 1;
      }

      calcOut.duration = endMow - this.state.startMow;
      calcOut.durationTime = TimetableModel.mow2time(calcOut.duration).daytime;
    }

    // console.log(calcOut);
    return(calcOut);
  }

  timeToMinutes = (time) => {
    const [startHr, startMin] = time.split(":");
    return parseInt(startHr)*60 + parseInt(startMin);
  }

  updateActivity = (e) => {
    this.setState({ activity: e.target.value });
  }

  updateStart = (e) => {
    this.setState({ start: parseInt(e.target.value) });
  }

  updateStartDow = (e) => {
    this.setState(this.recalcTimes({ startDow: e.target.value }));
  }

  updateStartTime = (e) => {
    this.setState(this.recalcTimes({ startTime: e.target.value }));
  }

  updateDurationTime = (e) => {
    this.setState(this.recalcTimes({ durationTime: e.target.value }));
  }

  updateEndTime = (e) => {
    this.setState(this.recalcTimes({ endTime: e.target.value }));
  }

  updateDuration = (e) => {
    this.setState(this.recalcTimes({ duration: e.target.value }));
  }

  create = () => {
    this.props.callback(this.state.activity, this.state.startMow, this.state.duration);
    this.setState({ activity: "", start: this.state.start + this.state.duration });
  }

  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="modTimeblockEditor">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">New time block</h4>
              <button type="button" className="btn close" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <form className="container-fluid">
                <div className="row">
                  <div className="form-group col-12">
                    {/* <label htmlFor="activity">Activity:</label> */}
                    <input type="text" id="activity" name="activity" placeholder="What are you planning to do?" className="form-control form-control-lg" value={ this.state.activity } onChange={ this.updateActivity } />
                  </div>
                </div>
                <div className="row">
                  <div className="btn-group col-12" role="group" aria-label="Weekday selector" onChange={ this.updateStartDow }>
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnMon" autoComplete="off" value="1" defaultChecked={ this.state.startDow == 1 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnMon">Mon</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnTue" autoComplete="off" value="2" defaultChecked={ this.state.startDow == 2 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnTue">Tue</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnWed" autoComplete="off" value="3" defaultChecked={ this.state.startDow == 3 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnWed">Wed</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnThu" autoComplete="off" value="4" defaultChecked={ this.state.startDow == 4 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnThu">Thu</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnFri" autoComplete="off" value="5" defaultChecked={ this.state.startDow == 5 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnFri">Fri</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnSat" autoComplete="off" value="6" defaultChecked={ this.state.startDow == 6 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnSat">Sat</label>
                    
                    <input type="radio" className="btn-check" name="btnWeekday" id="btnSun" autoComplete="off" value="0" defaultChecked={ this.state.startDow == 0 } />
                    <label className="btn btn-outline-secondary" htmlFor="btnSun">Sun</label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-4">
                    <label htmlFor="startTime" className="form-label">Start:</label>
                    <input type="time" name="startTime" className="form-control text-center" value={ this.state.startTime } onChange={ this.updateStartTime } />
                  </div>
                  <div className="form-group col-4">
                    <label htmlFor="duration" className="form-label">Duration:</label>
                    <input type="time" name="durationTime" className="form-control text-center" value={ this.state.durationTime } onChange={ this.updateDurationTime } />
                  </div>
                  <div className="form-group col-4 position-relative">
                    <label htmlFor="startTime" className="form-label">End:</label>
                    <div className="input-group">
                      <input type="time" name="endTime" className="form-control text-center" value={ this.state.endTime } onChange={ this.updateEndTime } />
                      { (this.state.endDayRel) ? <span className="badge bg-warning rounded-pill position-absolute" style={{zIndex: 100}}>+1</span> : <></> }
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="accordion" id="accordionTimeblockEditorDebug">
                    <div className="accordionItem">
                      <h5 className="accordionHeader">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                          Debug info
                        </button>
                      </h5>
                      <div id="collapseOne" className="accordion-collapse collapse">
                        <div className="accordion-body">
                          <table className="table table-sm">
                            <tbody>
                              <tr>
                                <th>startMow:</th>
                                <td>{ this.state.startMow }</td>
                              </tr>
                              <tr>
                                <th>duration:</th>
                                <td>{ this.state.duration }</td>
                              </tr>
                              <tr>
                                <th>endTime:</th>
                                <td>{ this.state.endTime }</td>
                              </tr>
                              <tr>
                                <th>endDayRel:</th>
                                <td>{ this.state.endDayRel }</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={ this.create }>Create</button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TimeblockEditor;