import React from 'react';

class NotImplemented extends React.Component {
  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="modNotImplemented">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sorry!</h4>
              <button type="button" className="btn close" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <p>We are sorry, this feature is not implemented yet! Stay tuned!</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotImplemented;