import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import '@popperjs/core';
import * as bootstrap from 'bootstrap';
import './index.css';
import App from './App';
import TimetableModel from './TimetableModel';
import reportWebVitals from './reportWebVitals';

var timetable = new TimetableModel([
  /* 
          {activity: 'Snídaně', start: 480, duration: 30},
          {activity: 'Oběd', start: 720, duration: 60},
          {activity: 'Spánek', start: 1320, duration: 480},
          {activity: 'Večeře', start: 1210, duration: 45},
          {activity: 'Snídaně', start: 1920, duration: 30},
          {activity: 'Oběd', start: 2160, duration: 60},
          {activity: 'Spánek', start: 2760, duration: 480},
          {activity: 'Večeře', start: 2650, duration: 45},
          {activity: 'Daleká cesta', start: 8000, duration: 5960},
          {activity: 'Spánek', start: 9960, duration: 480}
   */
]);
 

const root = ReactDOM.createRoot(document.getElementById('app_168h'));
root.render(
  <React.StrictMode>
    <App timetable={ timetable } />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
