import React from 'react';
import Timeblock from './Timeblock';

class Timetable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="panel col-xl-8">
        <div className='row'>
          <h2 className="col-9">
            My timetable
          </h2>
          <button className="btn btn-primary col" data-bs-toggle="modal" data-bs-target="#modTimeblockEditor">+</button>&nbsp;
          <div className="btn-group col-2" role="group" aria-label="Switch timetable view">
            <button className="btn btn-outline-secondary active">Lv</button>
            <button className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#modNotImplemented">Ht</button>
            <button className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#modNotImplemented">Vt</button>
          </div>
        </div>
        <table className="col-12 table table-striped table-sm align-middle">
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Duration</th>
              <th>Activity</th>
              <th>Category</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { this.props.timeBlocks.map((timeBlock, idx) => <Timeblock key={idx} idx={idx} timeBlock={timeBlock} callbackDelete={ this.props.callbackDelete } />) }
          </tbody>
        </table>
      </div>
    )
  }
}

export default Timetable;