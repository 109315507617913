import React from 'react';
import './App.css';
import Header from './Header';
import Timetable from './Timetable';
import TimeblockEditor from './TimeblockEditor';
import CategoryEditor from './CategoryEditor';
import Profile from './Profile';
import Footer from './Footer';
import TimetableModel from './TimetableModel';
import NotImplemented from './NotImplemented';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timetable: this.props.timetable,
      currentMow: TimetableModel.minuteOfWeek()
    };
  }

  componentDidMount = () => {
    let data = localStorage.getItem("168h");
    this.setState({ timetable: Object.assign(this.props.timetable, JSON.parse(data))});
    this.updateCurrentTime();
    this.timerId = setInterval(this.updateCurrentTime, 1000);
 }

  createTimeblock = (activity, start, duration) => {
    this.setState({ timetable: this.state.timetable.createTimeblock(activity, start, duration) }, () => localStorage.setItem("168h", JSON.stringify(this.state.timetable)));
  }

  deleteTimeblock = (idx) => {
    this.setState({ timetable: this.state.timetable.deleteTimeblock(idx) }, () => localStorage.setItem("168h", JSON.stringify(this.state.timetable)));
  }

  updateCurrentTime = () => {
    this.setState({
      currentMow: TimetableModel.minuteOfWeek(),
      currentBlock: this.state.timetable.getCurrentTimeblock(TimetableModel.minuteOfWeek())
    });
  }

  render() {
    return <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
        <a href="#" className="navbar-brand">168h.life</a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          <ul className="navbar-nav nav ms-auto">
            <li className="nav-item"><a href="#" className="nav-link active">Timetable</a></li>
            <li className="nav-item"><a href="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#modNotImplemented">Planner</a></li>
            <li className="nav-item"><a href="#" className="nav-link" data-bs-toggle="modal" data-bs-target="#modProfile">Profile</a></li>
          </ul>
        </div>
        </div>
      </nav>



      <div className="container">
        <div className="row">
          <Header currentBlock={ this.state.currentBlock } currentMow={ this.state.currentMow } />
        </div>
        <div className="row">
          <Timetable timeBlocks={ this.state.timetable.timeBlocks } callbackDelete={ this.deleteTimeblock } />
          <CategoryEditor />
        </div>
        <Footer />
      </div>



      <TimeblockEditor callback={ this.createTimeblock } />
      <Profile />
      <NotImplemented />
    </>
  }
}

export default App;
