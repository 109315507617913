import React from 'react';
import TimetableModel from './TimetableModel';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="panel col-12">
      <h1>{ TimetableModel.mow2time(this.props.currentMow).weektimeReadable3 } is time for { this.props.currentBlock ? this.props.currentBlock.activity : "(no planned activity)" }</h1>
      <h2>XX:XX until ABCD</h2>
    </div>
  }
}

export default Header;