import React from 'react';

class Profile extends React.Component {
  render() {
    return (
      <div className="modal fade" tabIndex="-1" role="dialog" id="modProfile">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Profile settings</h4>
              <button type="button" className="btn close" data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <ul>
                <li>Language: English / Czech</li>
                <li>Plan: Free / [ Symbolic / Paid ]</li>
                <li>Week starts at: Monday / Sunday / [ Tuesday / Wednesday / Thursday / Friday / Saturday ]</li>
                <li>Display seconds: No / [ Yes ]</li>
                <li>Theme: Light / [ Dark ]</li>
                <li>Granularity: 1h / [ 30m / 15m / 5m / 1m ]</li>
                <li>Clock style: Simple / [ 8seg / Analog / ... ]</li>
              </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save changes</button>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile;