import React from 'react';

class CategoryEditor extends React.Component {
  render() {
    return (
      <div className="panel col-xl-4">
        <h2>Categories</h2>
        <ul>
          <li>Sleep</li>
          <li>Work</li>
          <li>Self-care</li>
          <li>Family</li>
          <li>Leisure</li>
          <li>Time left</li>
          <li><input type="text" /><button>Add</button></li>
        </ul>
      </div>
    )
  }
}

export default CategoryEditor;